import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import PageHeader from '../../components/PageHeader';
import ValueProps from '../../components/ValueProps';
import FeaturesGrid from '../../components/FeaturesGrid';
import Section from '../../components/Section';
import Heading from '../../components/Styles/Heading';
import Text from '../../components/Styles/Text';
import Box from '../../components/Styles/Box';
import Grid from '../../components/Styles/Grid';
import Card from '../../components/Styles/Card';

const WebDesignPage = (props) => {
  const { data } = props;
  return (
    <Layout>
      <SEO title="Web Design Packages &amp; Tech Support in Boston" />
      <PageHeader
        heading="Web Design <span class='highlight'>Packages</span> "
        subHeading="ALTdev is one of the best website maintenance services company, helping businesses across the globe improve the performance and security of their websites. Whether you’re looking for a one time fix or an ongoing engament, ALTdev provides the experience and expertise your company demands. Start focusing on your business while we keep your website secure, healthy and up-to-date."
        buttonText="Request a Quote"
        buttonLink="/contact/"
      />

      <Section>Utility Nav - Package 1 - Package 2 - Package 3</Section>

      <SectionPackages>
        <Heading>Package Pricing</Heading>
        <Grid gridTemplateColumns={['1fr', '1fr 1fr 1fr']} gridGap={4}>
          <Box>
            <Card boxShadow={true}>
              <h3>Landing Page / Single Page Website</h3>
              <p>
                Our express website package is built for businesses&nbsp;who
                want a&nbsp;quick and affordable design. If you’re looking for a
                simple, but powerful website that communicates your brand, this
                package is for you.
              </p>
              <ul>
                <li>
                  Customization of website branding (colors, backgrounds, fonts,
                  textures)
                </li>
                <li>State of the art WordPress content management system</li>
                <li>
                  Up to 5 pages of content will be formatted in the new website
                </li>
                <li>WordPress training after website launch</li>
              </ul>
            </Card>
          </Box>
          <Box>
            <Card boxShadow={true}>
              <h3>Basic WordPress</h3>
              <p>
                Our entrepreneurial website package gives your business a
                <a href="https://www.interactivepalette.com/web-design/google-uses-mobile-friendliness-as-ranking-factor/">
                  mobile-responsive website
                </a>
                and customized features to accentuate your brand. Plus, we add
                in on-going maintenance so your site stays updated and
                performing well.
              </p>
              <ul>
                <li>Design of custom homepage</li>
                <li>
                  Coding and integration into our state of the art WordPress
                  content management system
                </li>
                <li>
                  The new website will be coded to be a Responsive “mobile
                  friendly” website
                </li>
                <li>
                  Up to 10 pages of content will be formatted in the new website
                </li>
                <li>
                  Monthly reporting on website performance will be generated
                  using Google Analytics
                </li>
                <li>
                  A sitemap will be generated and submitted to Google, Bing and
                  Yahoo for indexing
                </li>
                <li>
                  All the meta tags on the website will be updated to optimize
                  the website
                </li>
                <li>WordPress training before website launch</li>
              </ul>
            </Card>
          </Box>
          <Box>
            <Card boxShadow={true}>
              <h3>Custom WordPress</h3>
              <p>
                Our Small Biz Launcher&nbsp;delivers everything you need for a
                <a href="https://www.interactivepalette.com/6-elements-that-describe-an-effective-online-presence/">
                  powerful&nbsp;online presence
                </a>
                . A host of customized features gives your&nbsp;business
                leverage above competitors, including (unique features +
                specific benefits).
              </p>
              <ul>
                <li>Initial strategy meeting</li>
                <li>Custom design of homepage</li>
                <li>
                  Custom general inside page design which is your content pages,
                  inside navigation, etc.
                </li>
                <li>Custom design of mobile website</li>
                <li>
                  Coding and integration into our state of the art WordPress
                  content management system
                </li>
                <li>
                  The new website will be coded to be a Responsive “mobile
                  friendly” website
                </li>
                <li>
                  Up to 20 pages of content will be formatted in the new website
                </li>
                <li>
                  Monthly reporting on website performance will be generated
                  using Google Analytics
                </li>
                <li>
                  A sitemap will be generated and submitted to Google, Bing and
                  Yahoo for indexing
                </li>
                <li>
                  All the meta tags on the website will be updated to optimize
                  the website
                </li>
                <li>WordPress training before website launch</li>
              </ul>
            </Card>
          </Box>
        </Grid>
      </SectionPackages>
    </Layout>
  );
};


const SectionPackages = styled(Section)`
  background: #fbfbfb;
`;

export default WebDesignPage;

export const query = graphql`
  query {
    rocketDeveloper: file(relativePath: { eq: "rocket-developer.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    contentMarketing: file(
      relativePath: { eq: "content-marketing_202307290.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gatsbyIcon: file(relativePath: { eq: "gatsby-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
