import React from 'react';
import styled from 'styled-components';

import CardFeature from './CardFeature';
import Heading from './Styles/Heading';
import Section from './Section';
import Grid from './Styles/Grid';
import Inner from './Styles/Inner';

const ValueProps = (props) => {
  const { heading, data } = props;
  return (
    <ValueProps__wrap topDivider={true}>
      {heading && (
        <Heading textAlign="center" marginBottom="1em">
          {heading}
        </Heading>
      )}
      <Grid columns={4}>
        {data.map((col) => (
          <CardFeature heading={col.heading} text={col.text} icon={col.icon} />
        ))}
      </Grid>
    </ValueProps__wrap>
  );
};

const ValueProps__wrap = styled(Section)`
  background: var(--lightBlue);
  ${Grid} {
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
  }
`;

export default ValueProps;
